.section-wallet {
    background: #01010D;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.popup-wallet {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: transparent;
    padding-top: 140px;
    z-index: 3;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.wrap-wallet {
    background: #313E4C;
    height: 100%;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding: 40px 24px 0 24px;
}
.title-wallet {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    font-size: 21px;
    font-family: "SFProDisplay-Medium";
    color: #fff;
    margin-bottom: 10px;
    font-weight: 500;
}
.text-wallet {
    font-size: 16px;
    line-height: 21px;
    font-family: "SFProDisplay-Regular";
    color: #939EAB;
    margin-bottom: 40px;

}
.btn-close {
    width: 20px;
    height: 20px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}
.btn-close img {
    width: 14px;
    height: 14px;
}


.wallet-item {
    text-decoration: none;
    padding: 10px;
    background-color: transparent;
    border-radius: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #fff;
    font-size: 16px;
    line-height: 21px;
    font-family: "SFProDisplay-Regular";
    border: 1px solid #46525E;
}
.wallet-item img {
    width: 36px;
    height: 36px;
    margin-right: 10px;
}

.wallet-top {
    width: 100%;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 30px;
    position: relative;
}
.wallet-top::before {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 2;
    position: absolute;
}
.wallet-top img {
    width: 60px;
    height: 60px;
    z-index: 1;
}

@media (min-width: 768px) {
    .list-wallet {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        row-gap: 15px;
        -webkit-column-gap: 15px;
           -moz-column-gap: 15px;
                column-gap: 15px;
    }

    .wallet-item {
        border-radius: 10px;
    }

    .list-wallet>* {
        width: calc(50% - 7.5px);
    }
}


@media (max-width: 767px) {
    .list-wallet {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        row-gap: 8px;
    }
}