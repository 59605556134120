.section-home {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center center;
    background-image: url('../../assets/images/big-2.png');
    background-size: cover;
}

.container {
    margin: 0 auto;
    height: 100%;
}
.bg-text {
    margin-top: 200px;
}
.bg-text img {
    width: 307px;
    height: 67px;
}


.section-bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 60px;
}


.text {
    color: #fff;
    font-size: 21px;
    line-height: 30px;
    font-family: 'SFProDisplay-Regular';
    font-weight: 400;
}

.text span {
    color: #C8C7D8;
}

.text-title {
    font-size: 30px;
    color: #FF83E3;
    font-family: 'SFProDisplay-Medium';
    font-weight: 500;
}

.content-text {
    text-align: left;
    margin-left: auto;
    margin-right: auto;
}

p {
    margin: 0;
}

.section-conetnt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    position: relative;
    height: 100%;
    overflow: auto;
}

.section-top,
.section-middle {
    text-align: center;
}

.wrap-content-home {
    padding: 0 26px;
    margin-top: 80px;
    padding-bottom: 50px;
}

.container {
    max-width: 100%;
}


@media (min-width: 1200px) {
    .container {
        width: 1200px;
    }
}

@media (min-width: 768px) {
    .wrap-content-home {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        margin-top: 100px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    .text {
        margin-top: 30px;
    }
    .section-bottom {
       width: 80%;
    }
}


@media (max-width: 767px) {
    .text-title,
    .text {
        text-align: left;
    }
    .text {
        margin-top: 20px;
    }
}