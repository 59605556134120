.section-verify {
    height: 100%;
    background: #313E4C;
}
.verify-content {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.verify-back {
    width: 24px;
    height: auto;
    padding: 70px 24px 20px 24px;
}
.form {
    padding: 0 24px;
}

.verify-back img {
    width: 18px;
    height: 12px;
}
.verify-title {
    font-size: 21px;
    color: #fff;
    font-weight: 700;
    font-family: "SFProDisplay-Bold";
    margin-bottom: 5px;
}
.verify-text {
    font-size: 16px;
    line-height: 21px;
    color: #939EAB;
    font-weight: 400;
    font-family: "SFProDisplay-Regular";
}
.verify-input {
    width: 100%;
    resize: none;
    height: 85px;
    padding: 12px 16px;
    border-radius: 14px;
    background-color: #46525E;
    color: #fff;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 400;
    font-family: "SFProDisplay-Regular";
    line-height: 1;
    margin-top: 40px;
}

.verify-input::-webkit-input-placeholder {
    font-size: 16px;
    font-weight: 400;
    font-family: "SFProDisplay-Regular";
    line-height: 1;
    opacity: 0.3;
    color: rgba(255, 255, 255, 0.3);
}

.verify-input::-moz-placeholder {
    font-size: 16px;
    font-weight: 400;
    font-family: "SFProDisplay-Regular";
    line-height: 1;
    opacity: 0.3;
    color: rgba(255, 255, 255, 0.3);
}

.verify-input:-ms-input-placeholder {
    font-size: 16px;
    font-weight: 400;
    font-family: "SFProDisplay-Regular";
    line-height: 1;
    opacity: 0.3;
    color: rgba(255, 255, 255, 0.3);
}

.verify-input::-ms-input-placeholder {
    font-size: 16px;
    font-weight: 400;
    font-family: "SFProDisplay-Regular";
    line-height: 1;
    opacity: 0.3;
    color: rgba(255, 255, 255, 0.3);
}

.verify-input::placeholder {
    font-size: 16px;
    font-weight: 400;
    font-family: "SFProDisplay-Regular";
    line-height: 1;
    opacity: 0.3;
    color: rgba(255, 255, 255, 0.3);
}
.btn-submit {
    width: 100% !important;
    border: none;
}
.loader {
    width: 15px;
    height: 15px;
    border: 2px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-animation: rotation 1s linear infinite;
    animation: rotation 1s linear infinite;
    margin-right: 5px;
}
.verify-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    padding: 0 30px;
    margin-top: 90px;
}

@-webkit-keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}